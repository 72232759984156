import Listing from "./pages/Listing";
import Form from "./pages/Form";
import './index.css'
import { Route, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import FormSuccess from "./pages/FormSuccess";
import Profile from "./pages/Profile";
import PDFupload from "./pages/PDFupload";
function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
<Route path="/" Component={Home} />
<Route path="/listing" Component={Listing} />
<Route path="/form" Component={Form} />
<Route path="/formSuccess" Component={FormSuccess} />
<Route path="/404" Component={NotFound} />
<Route path="/profile/:id" Component={Profile} />
<Route path="/pdf" Component={PDFupload} />
      </Routes>
    </div>
  );
}

export default App;
