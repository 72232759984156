export default function ProfileCard({props}) {
    return (

<div className="w-60 min-h-max bg-white border border-gray-200 rounded-lg shadow">
    <a href={props.profilePic}>
        <img className="rounded-t-lg w-60 h-60" src={props.profilePic} alt="" />
    </a>
    <div className="p-5">
    <p className="mb-2 text-l font-bold tracking-tight text-gray-900">Name : {props.name}</p>
    <h4 className="mb-3 font-normal text-gray-700"> dob :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.dob}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">birthplace :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.birthplace}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">height :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.height}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">qualification :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.qualification}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">occupation :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.occupation}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">expectations :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.expectations}   </span>
    </h4>
    <h4 className="mb-3 font-normal text-gray-700">caste :  
    <span className="mb-2 text-l font-bold tracking-tight text-gray-900">{props.caste}   </span>
    </h4> 
    </div>
</div>
    )
}