import { createContext, useContext, useState, useEffect } from 'react';
import axios from "axios"

const MatrimonyContext = createContext(null);

const useMatrimonyContext = () => useContext(MatrimonyContext);

const MatrimonyProvider = ({ children }) => {
  const [MatrimonyData, setMatrimonyData] = useState([]);

  useEffect(() => 
  {

    const fetchData = async () => {
        try {
            const res = await axios.get('https://react-matrimony.onrender.com/api/matrimony');
            setMatrimonyData(res.data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    
    fetchData();
    }
  , []);

  return (
    <MatrimonyContext.Provider value={{ MatrimonyData, setMatrimonyData }}>
      {children}
    </MatrimonyContext.Provider>
  );
};

export { MatrimonyProvider, useMatrimonyContext };