// import RangeSlider from "./RangeSlider"

export default function Filters({Gender}) {
  const [gender, setGender] = Gender
  const filterHandling = (e) => {
setGender(e.target.value)
  }
  const clearFilter = () => {
    setGender('') 
  }
  return (
    <div className="ml-4 invisible sm:visible">
      <h3 className="mb-4 font-semibold text-gray-900">
        Identification
      </h3>
      <button onClick={clearFilter}>Clear Filter</button>
      <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
        <li className="w-full border-b border-gray-200 rounded-t-lg">
          <div className="flex items-center ps-3">
            <input
              id="list-radio-license"
              type="radio"
              value="male"
              name="list-radio"
              checked={gender=== 'male' }
              onChange={filterHandling}
              className="w-4 h-4 text-orange-600 bg-gray-100 border-gray-300 focus:ring-orange-500" />
            <label
              htmlFor="list-radio-license"
              className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
            >
              Male
            </label>
          </div>
        </li>
        <li className="w-full border-b border-gray-200 rounded-t-lg">
          <div className="flex items-center ps-3">
            <input
              id="list-radio-id"
              type="radio"
              value="female"
              name="list-radio"
              checked={gender=== 'female' }
              onChange={filterHandling}
              className="w-4 h-4 text-orange-600 bg-gray-100 border-gray-300 focus:ring-orange-500"
            />
            <label
              htmlFor="list-radio-id"
              className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
            >
              Female
            </label>
          </div>
        </li>
      </ul>

      {/* <RangeSlider /> */}
    </div>
  );
}
