import React, { useState } from 'react';
import axios from 'axios';

export default function PDFupload() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });
    const [pdfFile, setPdfFile] = useState(null);

    const handleFileChange = (e) => {
        setPdfFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!pdfFile) {
            alert('Please select a PDF file to upload');
            return;
        }

        const formData = new FormData();
        formData.append('pdf', pdfFile);
        // console.log(formData, "x")
        // console.log(pdfFile, "y")

        try {
            const response = await axios.post('http://localhost:3009/pdf-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setFormData(response.data);
        } catch (error) {
            console.error('Error uploading PDF:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className='flex flex-col items-center justify-center gap-10 w-full'>
            <h1>Upload Resume</h1>
            <input type="file" accept="application/pdf" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>

            <h2>Personal Information</h2>
            <form className='flex flex-col items-center justify-center' >
                <label>
                    Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </label>
                <br />
                <label>
                    Email:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                </label>
                <br />
                <label>
                    Phone:
                    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                </label>
            </form>
        </div>
    );
}
