import { useState } from "react";
import Filters from "../components/Filters"
import ProfileCard from "../components/ProfileCard"
import Search from "../components/Search"
import { useMatrimonyContext } from "../context/matrimonyListing"
import { Link } from "react-router-dom";

export default function Listing(){
    let { MatrimonyData } = useMatrimonyContext();
    const [searchTerm, setSearchTerm] = useState("");
    const [gender, setGender] = useState('');

      const finalMatrimony = () => {
        console.log(MatrimonyData)
        MatrimonyData = MatrimonyData.filter(data => {
            if(gender === ''){
                return data
            }
            return data.gender=== gender && data
        })
        
        if(searchTerm === ''){
      return MatrimonyData
    }
    return MatrimonyData.filter(item => {
       return item.name.toLowerCase().includes(searchTerm.toLowerCase()) && item 
    })
}


    return (
        <div className="">

<Search search={[searchTerm, setSearchTerm]}/>
<div className="grid grid-cols-5 gap-2">
    <Filters Gender={[gender, setGender]}/>
    <div className="col-start-2 col-end-6">
        <div className="grid sm:grid-cols-4 gap-4">
    {finalMatrimony().map((profile,index)=>{
return(
    <div key={profile._id} >
        <Link to={`/profile/${profile._id}`}>
        <ProfileCard props={profile}/>
        </Link>
    </div>
)   }
    )}
        </div>
    </div>
    </div>
</div>
    )
}