import { Link } from 'react-router-dom'
import successImg from './success-icon-10.png' 
export default function FormSuccess(){
    return(
        <div className='flex flex-col gap-4 items-center justify-center h-screen text-center'>
        <img src={successImg} alt="success Form Filled ml-12" className='w-20 h-20'/>
        <p className='text-6xl'>Thank You</p>
        <h1>Form has been Successfully submitted</h1>
        <Link to="/listing" type="button" className="w-80 text-white bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Go to Vaar-Vaadhu Page</Link>
        </div>
    )
} 