import { Link } from 'react-router-dom';
import myImage from './lagn-photo.svg';
export default function Home(){
    return (
        <div className='flex sm:flex-row flex-col justify-around'>
            <div className='flex flex-col m-6 gap-6 text-xl font-medium justify-items-center'>
        <span className='text-orange-500 text-6xl'>Welcome to Bhagirath Gavandi Sagar Samaj Matrimony</span>
        <span className='text-orange-700 text-xl'>Where Tradition Meets Modernity!</span>
        <span className='text-4xl'>"जोडीची पहाट, साजरीने करूया जिवनाची साथ!"</span>
        <div className='flex flex-col gap-2'>
        <Link
              to="/listing" type="button" className="w-80 text-white bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none text-center">Vaar Vaadhu List</Link>
        <Link to="/form" type="button" className="w-80 text-orange-500 hover:text-white border border-orange-500 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Register yourself on portal</Link>
        </div>
            </div>
        <img src={myImage} alt="" style={{height: "35rem",
    width: "35rem"}} />
        </div>
    )
}