import { useState } from "react";
import { useMatrimonyContext } from "../context/matrimonyListing";
import { Link as RouterLink } from 'react-router-dom';


const Profile =  ()  => {

    let { MatrimonyData } = useMatrimonyContext();
    const id = window.location.pathname.split('profile/')[1]
    console.log(id,MatrimonyData)
    const user = MatrimonyData.filter(item => item._id === id)[0]


    const [isDetails, setDetails] = useState(false);
    // const profileData = {
    //   about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    //   skills: ['React', 'JavaScript', 'Tailwind CSS', 'Node.js'],
    // };
  
    const cancelHandler = () => setDetails(false);

    return (
        <div className="bg-gray-100 min-h-screen">
          <div className="container mx-auto py-6">
            <div className="bg-white rounded-lg shadow-md p-6 md:flex md:items-center md:justify-between">
              <div className="md:flex">
                <div className="md:flex-shrink-0">
                  <img
                    src={user.profilePic}
                    alt="Profile"
                    className="w-32 h-32 rounded-full"
                  />
                </div>
                <div className="mt-4 md:mt-0 md:ml-6">
                  <h2 className="text-xl font-semibold">{user.name}</h2>
                  <p className="text-gray-500">{user.email}</p>
                  <p className="text-gray-700 mt-2">DOB : {user.dob} and TOB : {user.tob}</p>
                  <p>{user.qualification}</p>
                  <p>{user.occupation}</p>
                  <p>{user.caste}</p>
                </div>
              </div>
              <div className="mt-6 md:mt-0 md:ml-6 md:flex md:items-center md:justify-end">
                <RouterLink
                  to={`/share/profile/${user.uid}`}
                  className="bg-purple-400 text-white py-2 px-4 rounded-full text-sm font-semibold hover:bg-purple-500"
                >
                  Share Profile
                </RouterLink>
              </div>
            </div>
          </div>
          <div className="container mx-auto mt-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="border-b border-gray-200">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-4 sm:mb-0">
                    <h3 className="text-lg font-semibold">Profile Details</h3>
                  </div>
                  {!isDetails && (
                    <button
                      className="bg-purple-500 text-white py-2 px-4 rounded-full text-sm font-semibold hover:bg-purple-600"
                      onClick={() => setDetails(true)}
                    >
                      Edit Details
                    </button>
                  )}
                </div>
              </div>
              {/* Tab Content */}
              <div className="mt-4">
                {!isDetails ? (
                  <div className="mb-4">
                    {/* Render UserDetails component */}
                    <p>User Details Component</p>
                  </div>
                ) : (
                  <div>
                    {/* Render ProfileDetails component */}
                    <p>Profile Details Component</p>
                    <button
                      className="bg-red-500 text-white py-2 px-4 rounded-full text-sm font-semibold hover:bg-red-600"
                      onClick={cancelHandler}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
}

export default Profile;
    // <div className="bg-gray-100 min-h-screen flex justify-center items-center">
    //   <div className="max-w-md bg-white p-8 rounded-lg shadow-md">
    //     {/* Profile Image */}
    //     <div className="flex justify-center mb-4">
    //       <img
    //         src="profile.jpg"
    //         alt="Profile shows"
    //         className="w-32 h-32 rounded-full"
    //       />
    //     </div>
    //     {/* User Information */}
    //     <div className="text-center">
    //       <h2 className="text-xl font-semibold">John Doe</h2>
    //       <p className="text-gray-500">Web Developer</p>
    //     </div>
    //     {/* Social Links */}
    //     <div className="flex justify-center mt-4">
    //       <a
    //         href="www"
    //         className="text-gray-500 hover:text-blue-500 mx-2"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-6 w-6"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M13 10V3L4 14h7v7l9-11h-7z"
    //           />
    //         </svg>
    //       </a>
    //       <a
    //         href="www"
    //         className="text-gray-500 hover:text-blue-500 mx-2"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-6 w-6"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //           />
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M15 8a6 6 0 00-6 6 6 6 0 006 6 6 6 0 006-6 6 6 0 00-6-6z"
    //           />
    //         </svg>
    //       </a>
    //       <a
    //         href="www"
    //         className="text-gray-500 hover:text-blue-500 mx-2"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-6 w-6"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M12 14l9-5-9-5-9 5 9 5z"
    //           />
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M12 14l9-5-9-5-9 5 9 5z"
    //           />
    //         </svg>
    //       </a>
    //     </div>
    //     {/* Bio */}
    //     <div className="text-center mt-4">
    //       <p className="text-gray-600">
    //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
    //         vehicula, dui vel aliquet sollicitudin.
    //       </p>
    //     </div>
    //   </div>
    // </div>